<template>
  <form @submit.prevent="onSubmit">
    <div class="form-row">
      <div class="col-6">
        <div class="form-group">
          <label for="start">Von</label>
          <input type="date" class="form-control form-control-sm" required v-model="start" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="end">Bis</label>
          <input type="date" class="form-control form-control-sm" required v-model="end" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="customer">Kunde</label>
      <div class="input-group input-group-sm mb-3">
        <input
          type="text"
          class="form-control form-control-sm"
          v-model="query"
          placeholder="Kundennummer suchen..."
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <i class="fa fa-fw fa-search"></i>
          </div>
        </div>
      </div>
      <select v-model="customer" class="form-control form-control-sm" required>
        <option :value="null" disabled>Kunde auswählen...</option>
        <option
          :value="customer"
          :key="customer._id"
          v-for="customer in customers"
        >{{customer.name}}</option>
      </select>
    </div>
    <div class="form-group" v-if="customer">
      <div class="form-group">
        <strong>Kundeninfo</strong>
      </div>
      <div class="form-group">
        <label for="customerId">Kundennummer</label>
        <input
          type="text"
          class="form-control form-control-sm"
          :value="customer.customerId"
          disabled
        />
      </div>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control form-control-sm" :value="customer.name" disabled />
      </div>
      <div class="form-group">
        <label for="zipcode">Postleitzahl</label>
        <input type="text" class="form-control form-control-sm" :value="customer.zipcode" disabled />
      </div>
      <div class="form-group">
        <label for="city">Stadt</label>
        <input type="text" class="form-control form-control-sm" :value="customer.city" disabled />
      </div>
    </div>
    <div class="form-group" v-if="availableWeights.length">
      <strong>Gewichte</strong>
    </div>
    <div class="form-group" v-for="weight in availableWeights" :key="weight.type">
      <div class="input-group">
        <input type="text" class="form-control form-control-sm" :value="weight.type" disabled />
        <input type="number" v-model.number="weight.amount" class="form-control form-control-sm" />
      </div>
      <small class="text-muted">Verfügbar: {{weight.amountAvailable}}</small>
    </div>
    <div class="form-group mb-0">
      <button
        type="submit"
        class="btn btn-block btn-success btn-sm"
        :disabled="requesting || role ==='guest'"
      >Speichern</button>
    </div>
    <div class="form-group mb-0">
      <div class="alert alert-danger" v-if="errMsg">{{errMsg}}</div>
    </div>
  </form>
</template>

<script>
import { format, getTime, parseISO } from 'date-fns';
import { debounce } from 'lodash';

export default {
  name: 'create-user-form',
  props: {
    errMsg: {
      type: String,
      required: true,
    },
    requesting: {
      type: Boolean,
      required: true,
    },
    availableWeights: {
      type: Array,
      default: () => [],
    },
    customers: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: 'guest',
    },
  },
  watch: {
    start: {
      handler: debounce(function () {
        this.$emit('startEndChange', { start: this.start, end: this.end });
      }, 1000),
    },
    end: {
      handler: debounce(function () {
        this.$emit('startEndChange', { start: this.start, end: this.end });
      }, 1000),
    },
    query: {
      handler: debounce(function () {
        this.$emit('queryChange', this.query);
      }, 500),
    },
    customers: {
      handler: function (customers) {
        if (customers.length === 1) {
          this.customer = customers[0];
        } else {
          this.customer = null;
        }
      },
    },
  },
  data() {
    return {
      start: format(new Date(), 'yyyy-MM-dd'),
      end: format(new Date(), 'yyyy-MM-dd'),
      customer: null,
      weights: [],
      query: '',
    };
  },
  methods: {
    onSubmit() {
      this.availableWeights.forEach((weight) => {
        delete weight.amountAvailable;
        delete weight._id;
        delete weight.sortVal;
      });

      delete this.customer._id;

      this.$emit('createBooking', {
        start: getTime(parseISO(this.start)),
        end: getTime(parseISO(this.end)),
        customer: this.customer,
        weights: this.availableWeights,
      });
    },
  },
};
</script>
